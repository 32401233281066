import React, { useState, useEffect } from "react";

//Components
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import ReactHtmlParser from 'react-html-parser';

//Language Files
import zhTextContent from '../../data/zh-TW/home.json';
import enTextContent from '../../data/en-US/home.json';

//Store Language Context
import { useLanguage } from '../../store/LanguageContext';

//Import the projects data
import zhProjectsData from "../../data/zh-TW/projectsData.json"
import enProjectsData from "../../data/en-US/projectsData.json"

//Style
import './home.style.css';

//Assets
import coverImage from '../../assets/coverImage.png'

interface HomeProps {
  //Add Props if needed
}

interface ProjectData {
  uid: string;
  order: number;
  protected: boolean;
  title: string;
  shortDesc: string;
  fullDesc: string;
  tags: string[];
  shortHeaderImagePath: string;
  headerImagePath: string;
  roles: string[];
  memberAndDate: string;
  projectSectionPaths: string[];
}

interface ProjectsData {
  [key: string]: ProjectData;
}

const Home: React.FC<HomeProps> = () => {
  //Get selected language
  const { selectedLanguage } = useLanguage();
  const [textContent, setTextContent] = useState(selectedLanguage === 'ZH' ? zhTextContent : enTextContent);

  const zhProjects: ProjectsData = zhProjectsData;
  const enProjects: ProjectsData = enProjectsData;

  const [projectsData, setProjectsData] = useState(selectedLanguage === 'ZH' ? zhProjects : enProjects);


  useEffect(() => {
    setProjectsData((prevProjectsData: object) => {
      return selectedLanguage === 'ZH' ? zhProjects : enProjects;
    });

    setTextContent(prevTextContent => {
      return selectedLanguage === 'ZH' ? zhTextContent : enTextContent;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  // Convert the object into an array and sort it based on the order property
  const sortedProjects = Object.values(projectsData).sort((a, b) => a.order - b.order);


  return (
    <React.Fragment>
      <div className="background-png"></div>
      <div className="content-margin content">
        <div className="home-header-container">
          <div className="text-container subtitle-2">
            <span>{ReactHtmlParser(textContent.cover_text)}</span>
          </div>
          <div className="image-container">
            <img src={coverImage} alt="Cover" className="responsive-image" />
          </div>
        </div>
        <div className="projects-area">
          <div className="projects-area-label">
            <h1 className="heading-1">{ReactHtmlParser(textContent.projects_area_label)}</h1>
          </div>
          <div className="project-items">
            {sortedProjects.map((project, index) => (
              <ProjectCard projectDataItem={project} key={index} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default Home;

