import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

//Language Files
import zhTextContent from '../../data/zh-TW/navbar.json';
import enTextContent from '../../data/en-US/navbar.json';

//Store Language Context
import { useLanguage } from '../../store/LanguageContext';

//Style
import './navbar.style.css';

//Assets
import menuIcon from '../../assets/menuIcon.svg'
import crossIcon from '../../assets/crossIcon.svg'
import { ReactComponent as DropdownIcon } from '../../assets/dropdownIcon.svg';

interface NavbarProps {
    // Add if neeed
}

const Navbar: React.FC<NavbarProps> = () => {
    const [showMenu, setShowMenu] = useState(false);
    const { selectedLanguage, setSelectedLanguage } = useLanguage();
    const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
    const [textContent, setTextContent] = useState(zhTextContent);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
        setShowLanguageDropdown(false);
    };

    const toggleLanguageDropdown = () => {
        setShowLanguageDropdown(!showLanguageDropdown);
    };

    const handleMenuIconClick = () => {
        setShowMenu(false); // Close the menu
    };

    const handleLanguageChange = (lang: string) => {
        setSelectedLanguage(lang);
        setShowLanguageDropdown(false);

        // Update text content based on the selected language
        const newTextContent: any = lang === 'ZH' ? zhTextContent : enTextContent;
        setTextContent(newTextContent);
    };

    return (
        <React.Fragment>
            <div className="navbar-container content-margin">
                <div className="navbar-brand">
                    <NavLink to="/" className="heading-1 navbar-brand">
                        {textContent.brand}
                    </NavLink>
                </div>
                <div className={`nav-links ${showMenu ? 'show-menu' : ''}`}>
                    <NavLink to="https://drive.google.com/file/d/1pgN-Yl2cmUW2Mo7mnT3LJPyoumFpdk6W/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="nav-link body-font">{textContent.links.external_resume}</NavLink>
                    <div className="dropdown" onMouseEnter={toggleLanguageDropdown} onMouseLeave={toggleLanguageDropdown}>
                        <div className="dropdown-toggle body-font">
                            {selectedLanguage === 'ZH' ? (
                                <div>
                                    <DropdownIcon />
                                    <span className="dropdown-text">中文</span>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <DropdownIcon />
                                    <span className="dropdown-text">EN</span>
                                </React.Fragment>
                            )}
                        </div>
                        {showLanguageDropdown && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={() => handleLanguageChange('ZH')}>中文</div>
                                <div className="dropdown-item" onClick={() => handleLanguageChange('EN')}>EN</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="hamburger-menu" onClick={toggleMenu}>
                    {showMenu ? (
                        <img src={crossIcon} alt="Close Menu" className='close-hamburger-menu' onClick={handleMenuIconClick} />
                    ) : (
                        <React.Fragment>
                            <img src={menuIcon} alt="Open Menu" />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {showMenu && (
                <div className="overlay">
                    <div className="menu-icons subtitle-2">
                        <NavLink to="/" className="nav-link" onClick={handleMenuIconClick}>{textContent.links.home_page}</NavLink>
                        <NavLink to="https://drive.google.com/file/d/1pgN-Yl2cmUW2Mo7mnT3LJPyoumFpdk6W/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="nav-link" onClick={handleMenuIconClick}>{textContent.links.external_resume}</NavLink>
                        <div className="language-option nav-link">
                            <span className={`language-link ${selectedLanguage === 'ZH' ? 'active' : ''}`} onClick={() => handleLanguageChange('ZH')}>中文</span>
                            <span className="separator"> | </span>
                            <span className={`language-link ${selectedLanguage === 'EN' ? 'active' : ''}`} onClick={() => handleLanguageChange('EN')}>EN</span>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Navbar;


