import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//To purify the external HTML content for project
import DOMPurify from "dompurify";

// External Packages
import ReactHtmlParser from 'react-html-parser';

//Store Language Context
import { useLanguage } from "../../store/LanguageContext";

//Style
import "./project.style.css";

// Assets
import { ReactComponent as GoToTopIcon } from '../../assets/dropdownIcon.svg';

//Language Files
import zhTextContent from "../../data/zh-TW/project.json";
import enTextContent from "../../data/en-US/project.json";

//Import the projects data
import zhProjectsData from "../../data/zh-TW/projectsData.json";
import enProjectsData from "../../data/en-US/projectsData.json";

interface ProjectProps {
  // Define any additional props if needed
}

interface ProjectData {
  uid: string;
  order: number;
  protected: boolean;
  title: string;
  shortDesc: string;
  fullDesc: string;
  tags: string[];
  shortHeaderImagePath: string;
  headerImagePath: string;
  roles: string[];
  memberAndDate: string;
  projectSectionPaths: string[];
}

interface ProjectsData {
  [key: string]: ProjectData;
}

interface UID {
  [key: string]: string | undefined;
}

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.tagName === 'A') {
    // Ensure rel="noopener noreferrer" is added for security
    node.setAttribute('rel', 'noopener noreferrer');

    // Add target="_blank" to external links
    node.setAttribute('target', '_blank');
  }
});

const Project: React.FC = () => {
  type htmlSectionsStateType = string[];

  const zhProjects: ProjectsData = zhProjectsData;
  const enProjects: ProjectsData = enProjectsData;

  // Access the `uid` parameter using the useParams hook
  const { uid } = useParams<UID>() || { uid: "projectNotExist" };

  // Explicitly check if uid is undefined
  const projectId: string = uid || "projectNotExist";

  //Get selected language
  const { selectedLanguage } = useLanguage();

  //Page Text, Project and MD Contents
  const [textContent, setTextContent] = useState(
    selectedLanguage === "ZH" ? zhTextContent : enTextContent
  );
  const [projectData, setProjectData] = useState(
    selectedLanguage === "ZH" ? zhProjects[projectId] : enProjects[projectId]
  );
  const [htmlSections, setHtmlSections] = useState<htmlSectionsStateType>([]);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    setProjectData((prevProjectsData: object) => {
      return selectedLanguage === "ZH"
        ? zhProjects[projectId]
        : enProjects[projectId];
    });

    setTextContent((prevTextContent) => {
      return selectedLanguage === "ZH" ? zhTextContent : enTextContent;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, selectedLanguage]);

  //Fetch HTML Data for the Project Sections
  useEffect(() => {
    Promise.all(
      projectData.projectSectionPaths.map((path: string) =>
        fetch(path)
          .then((res) => res.text())
          .catch((error) => {
            console.error("Error fetching HTML data:", error);
            return ""; // Return an empty string if fetching fails
          })
      )
    ).then((htmlSection) => {
      // Update the state with the array of HTML sections
      setHtmlSections(htmlSection);
    });
  }, [projectData.projectSectionPaths]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <div className="project-container">
        <div className="content-margin header-container">
          <div className="title">
            <h1 className="heading-1">{projectData?.title}</h1>
          </div>
          <div className="header-content">
            <p className="header-description body-font">
              {ReactHtmlParser(projectData?.fullDesc)}
            </p>
            <div className="roles-and-date">
              <div className="roles">
                {" "}
                <span className="side-heading body-font-2">
                  {ReactHtmlParser(textContent?.roles)}
                </span>
                <ul className="body-font">
                  {projectData?.roles.map((role: string, index: any) => (
                    <li key={index}>{role}</li>
                  ))}
                </ul>
              </div>
              <div className="date">
                <span className="side-heading body-font-2">{ReactHtmlParser(textContent?.memberAndDate)}</span>
                <div className="body-font">{ReactHtmlParser(projectData?.memberAndDate)}</div>
              </div>
            </div>
          </div>
          <div className="header-image-container">
            <img
              src={projectData?.headerImagePath}
              className="header-image"
              alt="Header"
            />
          </div>
        </div>
        <div className="article-container">
          {htmlSections.map((section, index) => (
            <div className="article-section" key={index}>
              <div
                className="html-section"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section) }}
                key={index}
              />
            </div>
          ))}
        </div>
      </div>
      {showScrollButton && (
        <button className="go-to-top-btn" onClick={handleGoToTop}>
          <GoToTopIcon />
          {ReactHtmlParser(textContent?.goToTop)}
        </button>
      )}
    </React.Fragment>
  );

};

export default Project;
